/**
 * The feature flag for the search all page
 *
 * @constant {string}
 */
export const searchAllFeature = 'test_flag_search_all';

/**
 * The feature flag for the shelter multi users functionality.
 *
 * @constant {string}
 */
export const flagShelterMultiUsers = 'test_flag_shelter_multi_users';

/**
 * The feature flag for the persistent search.
 *
 * @constant {string}
 */
export const flagPersistentSearch = 'test_flag_persistent_search';

/**
 * The feature flag for the map search.
 *
 * @constant {string}
 */
export const flagMapSearch = 'test_flag_map_search';

/**
 * The feature flag for the report pet send analytics.
 * 
 * @constant {string}
 */
export const flagReportPetSendAnalytics = 'test_flag_report_pet_send_analytics';
